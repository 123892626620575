"use client";

import {cn} from "@/lib/utils";

interface WrapperProps {
    children: React.ReactNode;
};

export const Wrapper = ({
                            children,
                        }: WrapperProps) => {

    return (
        <aside className={cn(
            "fixed left-0 py-2 flex flex-col w-[70px] lg:w-60 h-full bg-background-900 border-r border-[#2D2E35] z-50"
        )}>
            {children}
        </aside>
    );
};
