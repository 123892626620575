import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/app/app/(platform)/_components/container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/app/app/(platform)/_components/sidebar/navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Wrapper"] */ "/app/app/(platform)/_components/sidebar/wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/(platform)/_components/navbar/logo.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"]}],\"variableName\":\"font\"}");
