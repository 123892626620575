"use client";

import {usePathname} from "next/navigation";
import {
    Fullscreen,
    Home,
    KeyRound,
    Banknote,
    Users,
} from "lucide-react";

import {NavItem, NavItemSkeleton} from "./nav-item";

export const Navigation = ({user}: any) => {
    const pathname = usePathname();

    const routes = [
        // {
        //     label: "Stream",
        //     href: `/u/${user?.nickname}`,
        //     icon: Fullscreen,
        // },
        // {
        //     label: "Keys",
        //     href: `/u/${user?.nickname}/keys`,
        //     icon: KeyRound,
        // },
        // {
        //     label: "Chat",
        //     href: `/u/${user?.nickname}/chat`,
        //     icon: MessageSquare,
        // },
        {
            label: "Overview",
            href: `/admin`,
            icon: Home,
        },
        {
            label: "Users",
            href: `/admin/usermanagement`,
            icon: Users,
        },
        {
            label: "Subscriptions",
            href: `/admin/subscriptions`,
            icon: Banknote,
        },

    ];

    if (!user?.nickname) {
        return (
            <ul className="space-y-2">
                {[...Array(4)].map((_, i) => (
                    <NavItemSkeleton key={i}/>
                ))}
            </ul>
        );
    }

    return (
        <ul className="space-y-2 px-2 pt-4 lg:pt-0">
            {routes.map((route) => (
                <NavItem
                    key={route.href}
                    label={route.label}
                    icon={route.icon}
                    href={route.href}
                    isActive={pathname === route.href}
                />
            ))}
        </ul>
    );
};
